import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "依赖替换"
    }}>{`依赖替换`}</h1>
    <p>{`在使用 `}<strong parentName="p">{`monorepo`}</strong>{` 模式管理的项目中，替换依赖是非常常见的需求。对于这种场景，`}<strong parentName="p">{`Sigi`}</strong>{` 提供了 `}<inlineCode parentName="p">{`InjectionProvidersContext`}</inlineCode>{` 这个 API 来提供替换依赖的能力.`}</p>
    <p><a parentName="p" {...{
        "href": "https://codesandbox.io/s/sigi-recipes-dependencies-replacement-tktun"
      }}>{`依赖替换的例子`}</a></p>
    <iframe src="https://codesandbox.io/embed/sigi-recipes-dependencies-replacement-tktun?expanddevtools=1&fontsize=14&hidenavigation=1&module=%2Fsrc%2Findex.tsx&theme=dark" style={{
      "width": "100%",
      "height": "500px",
      "border": "0",
      "borderRadius": "4px",
      "overflow": "hidden"
    }} title="sigi-recipes-dependencies-replacement" allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media; usb" sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import "reflect-metadata";
import React from "react";
import { render } from "react-dom";
import { ClassProvider } from "@sigi/di";
import { useModule, InjectionProvidersContext } from "@sigi/react";
import { HttpErrorClient } from "./http-with-error.service";
import { HttpBetterClient } from "./http-better.service";

import { AppModule } from "./app.module";

const AppContainer = React.memo(({ appTitle }: { appTitle: string }) => {
  const [{ list }, dispatcher] = useModule(AppModule);

  const loading = !list ? <div>loading</div> : null;

  const title =
    list instanceof Error ? <h1>{list.message}</h1> : <h1>{appTitle}</h1>;

  const listNodes = Array.isArray(list)
    ? list.map((value) => <li key={value}>{value}</li>)
    : null;
  return (
    <div>
      {title}
      <button onClick={dispatcher.fetchList}>fetchList</button>
      <button onClick={dispatcher.cancel}>cancel</button>
      {loading}
      <ul>{listNodes}</ul>
    </div>
  );
});

function App() {
  const betterHttpProvider: ClassProvider<HttpErrorClient> = {
    provide: HttpErrorClient,
    useClass: HttpBetterClient,
  };
  return (
    <>
      <AppContainer appTitle="Always error" />
      <InjectionProvidersContext providers={[betterHttpProvider]}>
        <AppContainer appTitle="Better http client" />
      </InjectionProvidersContext>
    </>
  );
}

const rootElement = document.getElementById("app");
render(<App />, rootElement);
`}</code></pre>
    <h2 {...{
      "id": "警告-️"
    }}>{`警告 ⚠️`}</h2>
    <p>{`为了不影响性能，`}<strong parentName="p">{`InjectionProvidersContext`}</strong>{` 需要尽可能的放在 `}<strong parentName="p"><em parentName="strong">{`静态`}</em></strong>{` 上下文中。比如，在 `}<a parentName="p" {...{
        "href": "https://leetcode-cn.com/circle"
      }}>{`LeetCode CN`}</a>{` 的大部分 App 中，`}<strong parentName="p">{`InjectionProvidersContext`}</strong>{` 只出现在 `}<strong parentName="p">{`AppRoot`}</strong>{` 这一层级的组件中。永远不要将 `}<strong parentName="p">{`InjectionProvidersContext`}</strong>{` 放在动态上下文中:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`const EnhancedLoggerProvider: ClassProvider<Logger> = {
  provide: Logger,
  useClass: EnhancedLoggerForItemDetail,
}

const Item = React.memo(({ item }: { item }: Item) => {
  // very bad
  return (
    <InjectionProvidersContext providers={[EnhancedLoggerProvider]}>
      <div key={item.id}>
        <ItemDetail detail={item.data.detailObject} />
      </div>
    </InjectionProvidersContext>
  )
})
`}</code></pre>
    <p>{`如果你有类似的需求，即: `}<strong parentName="p">{`在组件级别而不是应用级别替换依赖`}</strong>{`，你可以在 `}<strong parentName="p">{`EffectModule`}</strong>{` 中编写逻辑去处理这种需求。比如注入多个依赖到同一个 `}<strong parentName="p">{`EffectModule`}</strong>{` 中，再依据不同的 `}<strong parentName="p">{`Payload`}</strong>{` 使用不同的依赖。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      